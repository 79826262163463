import { Button, Form, Input, Modal, Checkbox } from 'antd'
import UploadImage from './UploadImage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import { CheckCircleOutlined } from '@ant-design/icons'
import {regexPhoneNumber} from "../../constants";

const patternEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const patternSerial = /^\d{8}$/
function PopupRegisterForTravelSim({
  isOpen,
  setIsOpen,
  loading,
  handleRegisterTravelSim,
}) {
  const { t } = useTranslation('landingPage')
  const [form] = Form.useForm()
  const [passport_image, setPassportImage] = useState(null)
  const [passport_image_back, setPassportImageBack] = useState(null)
  const [selfie, setSelfie] = useState(null)
  const [sim_card_blank, setSimcardblankImage] = useState(null)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      passport_image: passport_image,
    })
    form.validateFields(['passport_image'])
  }, [passport_image])

  useEffect(() => {
    form.setFieldsValue({
      passport_image_back: passport_image_back,
    })
    form.validateFields(['passport_image_back'])
  }, [passport_image_back])

  useEffect(() => {
    form.setFieldsValue({
      selfie: selfie,
    })
    form.validateFields(['selfie'])
  }, [selfie]);

  useEffect(() => {
    form.setFieldsValue({
      sim_card_blank: sim_card_blank,
    })
    form.validateFields(['sim_card_blank'])
  }, [sim_card_blank])

  const onValuesChange = () => {
    setIsValid(checkObjectValidity(form.getFieldsValue()))
  }

  const onFieldsChange = () => {
    setIsValid(checkObjectValidity(form.getFieldsValue()))
  }

  const onFinish = (values) => {
    handleRegisterTravelSim({
      ...values,
    })
  }

  const checkObjectValidity = (obj) => {
    const requiredKeys = [
      'fullname',
      'phone_number',
      'address',
      'isChecked',
      'serial',
      'email',
      'passport_image',
      'passport_image_back',
      'selfie',
      'sim_card_blank',
    ]

    for (const key of requiredKeys) {
      if (
        !(key in obj) ||
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === '' ||
        obj[key] === false
      ) {
        return false
      }
    }

    if ('serial' in obj && obj['serial']) {
      if (!patternSerial.test(obj['serial'])) return false
    }

    // if ('email' in obj && obj['email']) {
    if (!patternEmail.test(obj['email'])) return false
    // }

    return true
  }

  useEffect(() => {
    form.resetFields()
  }, [isOpen])

  return (
    <Modal
      width="600px"
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <div className="popup-sim-owner">
        <div className="sim-owner-title">
          {t('Enter_Sim_Activation_Information')}
        </div>
        <Form
          className="form"
          form={form}
          layout="vertical"
          name="registerForOwner"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            name="fullname"
            label={t('fullname')}
            rules={[
              {
                required: true,
                message: t('message_input_your_name'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label={t('phone')}
            rules={[
              {
                required: true,
                message: t('message_input_your_phone'),
              },
              // {
              //   pattern: regexPhoneNumber,
              //   message: t('message_input_valid_phone'),
              // },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label={t('address')}
            rules={[
              {
                required: true,
                message: t('message_input_your_address'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('Email')}
            rules={[
              {
                required: true,
                message: t('message_input_your_email'),
              },
              {
                pattern: patternEmail,
                message: t('message_input_valid_email'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="cccd-div">
            <Form.Item
              name="passport_image"
              label={t('citizen_identification')}
              rules={[
                {
                  required: true,
                  message: t(
                    'message_upload_your_citizen_identification_front'
                  ),
                },
              ]}
            >
              <UploadImage text={t('front')} handleSetUrl={setPassportImage} />
            </Form.Item>
            <Form.Item
              name="passport_image_back"
              label={t('citizen_identification')}
              rules={[
                {
                  required: true,
                  message: t('message_upload_your_citizen_identification_back'),
                },
              ]}
            >
              <UploadImage
                text={t('back')}
                handleSetUrl={setPassportImageBack}
              />
            </Form.Item>
          </div>

          <div className="cccd-div align-items-center">
            <Form.Item name="selfie" label={t('selfie')} rules={[
              {
                required: true,
                message: t('message_upload_your_selfie'),
              },
            ]}>
              <UploadImage text={t('selfie')} handleSetUrl={setSelfie}/>
            </Form.Item>

            <div className={'selfie-description'}>
              <p className={'selfie-description-text'}>
                <Image alt={'check-icon'} width={12} height={12} src={'/images/common/check-icon.svg'}/> {t('selfie_description_1')}
              </p>

              <p className={'selfie-description-text'}>
                <Image alt={'check-icon'} width={12} height={12} src={'/images/common/check-icon.svg'}/> {t('selfie_description_2')}
              </p>
            </div>
          </div>

          <div className="cccd-div">
            <Form.Item
              name="sim_card_blank"
              label={t('warranty_card')}
              rules={[
                {
                  required: true,
                  message: t('message_upload_your_warranty_card'),
                },
              ]}
            >
              <UploadImage
                text={t('warranty_card')}
                handleSetUrl={setSimcardblankImage}
              />
            </Form.Item>

            <div className="phoisim-example">
              <Image
                src="/images/landingPage/phoisim-example-new.png"
                width="270"
                height="125"
                alt={'phoisim-example'}
              />
            </div>
          </div>

          <Form.Item
            name="serial"
            label={t('serial')}
            rules={[
              {
                required: true,
                message: t('message_input_your_serial'),
              },
              {
                pattern: patternSerial,
                message: t('message_input_valid_serial'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="commit">
            <Form.Item
              name="isChecked"
              valuePropName="checked"
              style={{ marginBottom: 0 }}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(),
                },
              ]}
            >
              <Checkbox className="text-commit">
                {t('confirm_register')}
              </Checkbox>
            </Form.Item>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
            disabled={!isValid}
            loading={loading}
          >
            {t('Request_Sim_Activation')}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}

export default PopupRegisterForTravelSim
