import { API_BASE_URL } from '../constants'
import axiosInstance from './auth/axiosInstance'

const callApiTemporary = async (url, config = {}) => {
  try {
    const response = await axiosInstance({
      url: API_BASE_URL + url,
      ...config,
      headers: {
        'ngrok-skip-browser-warning': true,
      },
    })
    return response.data
  } catch (error) {
    console.error('API Error:', error) // Ghi log lỗi (tuỳ chọn)
    // Trả về một giá trị mặc định hoặc thông báo lỗi tùy chọn
    return null
  }
}

export default callApiTemporary
