import React, {useEffect} from 'react';
import Image from "next/image";
import {useTranslation} from "next-i18next";
import {Button, Select} from "antd";
import {formatNumber} from "../../../utils/currencyConverter";


function SlickCarouselAntiTheftItem({dataItem, handleClickOrder}) {
  const { t } = useTranslation('mToM')
  return (
    <div className="carousel-anti-theft-item">
      <div className="image-section">
        <div className="image-block">
          <div className="image-item">
            <Image
              src={dataItem?.image}
              alt="anti-theft"
              width={338}
              height={338}
            />
          </div>
        </div>
      </div>
      <div className="content-section">
        <div className="carousel-title">{dataItem?.title}</div>
        <div
          className="carousel-brand"
        >
          {t('tourists-to-vietnam.brand')}
          <span className='carousel-brand-name'>{dataItem?.brand}</span>
        </div>
        <div
          className="carousel-made-in"
        >
          {t('tourists-to-vietnam.made_in')}
          <span className='carousel-made-in-name'>{dataItem?.made_in}</span>
        </div>
        <div className={'product-price'}>{formatNumber(dataItem?.price)}</div>

        <Button className='btn-order' onClick={() => handleClickOrder(dataItem?.title)}>{t('tourists-to-vietnam.order').toUpperCase()}</Button>

      </div>
    </div>
  );
}

export default SlickCarouselAntiTheftItem;
