import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Layout from '../common/Layout'
import SimOrder from './simOrder'
import TouristsToVietnam from './touristsToVietnam'
import Banner from './banner'
import AboutUs from './aboutUs'
import IntroduceSimM2M from './introduceSimM2M'
import WhereCanYouFindUs from './whereCanYouFindUs'
import ServicesProvided from './servicesProvided'

import OurPartners from './ourPartners'
import CustomerComments from './customerComments/customerComments'
import Contact from './contact'
import AboutEsim from "./AboutEsim";
import {useRouter} from "next/router";

const LandingPage = () => {
  // const { t, i18n } = useTranslation('m2m')
  // const currentLanguage = i18n.language
  const [isCallInfoModal, setIsCallInfoModal] = useState(false)
  const router = useRouter()

  const [valueSelect, setValueSelect] = useState('')
  const handleClickOrder = (product) => {
    router.push('#sim-order')
    setValueSelect(product)
  }

  return (
    <>
      <Layout isHeaderLandingPage isM2M>
        <div className="main">
          <div className="landing-page-m2m">
            <Banner />
            <TouristsToVietnam handleClickOrder={handleClickOrder} />
            <SimOrder setIsCallInfoModal={setIsCallInfoModal} isCallInfoModal={isCallInfoModal}  valueSelect={valueSelect}/>
            <AboutEsim />
            <IntroduceSimM2M />
            <CustomerComments />
            <OurPartners />
            <Contact setIsCallInfoModal={setIsCallInfoModal} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default LandingPage
