import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import SlickCarousel from '../common/Reuse/slick-carousel-m2m'
import { message } from 'antd'
import { M2M_TYPE } from '../../constants'
import callApiTemporary from '../../utils/callApiTemporary'
import SlickCarouselAntiTheft from "../common/Reuse/slick-carousel-anti-theft";

const TouristsToVietnam = ({handleClickOrder}) => {
  const { t, i18n } = useTranslation('mToM')

  const ANTI_THEFT_TAB_NAME = {
    vi: 'Thiết bị chống trộm',
    en: 'Anti-theft devices',
    zh: '防盗设备',
  }

  const ANTI_THEFT_DATA = {
    vi: {
      image: '/images/common/anti-theft-device.jpg',
      title: 'SimSmart CT01',
      brand: 'Simsmart',
      made_in: 'Việt Nam',
      price: 'Liên hệ'
    },
    en: {
      image: '/images/common/anti-theft-device.jpg',
      title: 'SimSmart CT01',
      brand: 'Simsmart',
      made_in: 'Vietnam',
      price: 'Contact'
    },
    zh: {
      image: '/images/common/anti-theft-device.jpg',
      title: 'SimSmart CT01',
      brand: 'Simsmart',
      made_in: '越南',
      price: '联系'
    }
  }

  const [tab, setTab] = useState(1)
  const [tabList, setTabList] = useState([])
  const [data, setData] = useState([])
  const [i18nData, setI18nData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData().then();
  }, [])

  const getUniqueSections = (data, language) =>
    [...new Set(data.map((item) => item.section?.[language]))].filter(Boolean)

  useEffect(() => {
    if (data?.length) {
      setTabList([...getUniqueSections(data, i18n.language), ANTI_THEFT_TAB_NAME[i18n.language]])
      if (tab === ANTI_THEFT_TAB_NAME[i18n.language]) {
        setI18nData([ANTI_THEFT_DATA[i18n.language]])
      } else {
        setI18nData(mappingData(data, i18n.language))
      }
    }
  }, [data, i18n.language])

  useEffect(() => {
    if (data?.length) {
      if (tab === ANTI_THEFT_TAB_NAME[i18n.language]) {
        setI18nData([ANTI_THEFT_DATA[i18n.language]])
      } else {
        setI18nData(mappingData(data, i18n.language))
      }
    }
  }, [tab])

  useEffect(() => {
    !!tabList?.length && setTab(tabList[0])
  }, [tabList])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await callApiTemporary(`/api/v1/landing-products`, {
        params: {
          limit: 50,
          page: 1,
          product_type: M2M_TYPE,
        },
      })
      const data = response?.data?.items
      setData(data)
      setLoading(false)
    } catch (error) {
      message.error('Đã có lỗi trong quá trình xử lý. Vui lòng thử lại sau!')
      setLoading(false)
    }
  }

  const mappingData = (data, language) =>
    data
      ?.filter((x) => x?.section?.[language] === tab)
      .map((pureData) => ({
        image: pureData?.image,
        id: pureData?.id,
        title: pureData?.title?.[language],
        subtitle: pureData?.subtitle?.[language],
        description: pureData?.description?.[language],
        section: pureData?.section?.[language],
        price: pureData?.price?.[language],
        discount_price: pureData?.discount_price?.[language],
        period_price: pureData?.period_price?.[language],
      }))


  return (
    <div className="container tourists-to-vietnam">
      <div className="tab-nav">
        {!!tabList?.length &&
          tabList.map((t, index) => {
            return (
              <div
                key={index}
                className={`tab-item ${tab === t && 'active'}`}
                onClick={() => setTab(t)}
              >
                <div className="tab-item-text">{t}</div>
                <div className="tab-divider" />
              </div>
            )
          })}
      </div>
      <div className="title">{tab === ANTI_THEFT_TAB_NAME[i18n.language]  ? t('tourists-to-vietnam.anti-theft-title') : t('tourists-to-vietnam.title')}</div>
      <div className="content">{tab === ANTI_THEFT_TAB_NAME[i18n.language]  ? t('tourists-to-vietnam.anti-theft-description') : t('tourists-to-vietnam.description')}</div>

      {tab === ANTI_THEFT_TAB_NAME[i18n.language] ? <SlickCarouselAntiTheft data={i18nData} handleClickOrder={handleClickOrder} /> : <SlickCarousel data={i18nData}/>}
    </div>
  )
}

export default TouristsToVietnam
