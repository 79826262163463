/* eslint-disable react/button-has-type */
import Image from 'next/image'
import Slider from 'react-slick'
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { currencyConverter } from '../../../utils/currencyConverter'
import SlickCarouselM2MItem from "./SlickCarouselM2mItem";
import SlickCarouselAntiTheftItem from "./slick-carousel-anti-theft-item";

const PrevButton = (props) => (
  <button
    {...props}
    className="button-arrow button-arrow-left"
    aria-label="Prev"
    type="button"
    style={{ left: props.left }}
  >
    {/* <span className="arrow left" /> */}
    <Image
      src="/images/common/Chevron-Left.svg"
      alt="Chevron-Left"
      width={50}
      height={50}
    />
  </button>
)

const NextButton = (props) => (
  <button
    {...props}
    className="button-arrow button-arrow-right"
    type="button"
    aria-label="Next"
    style={{ right: props.right }}
  >
    {/* <span className="arrow right" /> */}
    <Image
      src="/images/common/Chevron-Right.svg"
      alt="Chevron-Right"
      width={50}
      height={50}
    />
  </button>
)

const SlickCarouselAntiTheft = ({ data = [], isTravelAbroad, handleClickOrder }) => {
  // const { t } = useTranslation('touristsToVN')
  const { t } = useTranslation('mToM')

  const [innerWidth, setInnerWidth] = useState(0)
  useEffect(() => {
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth)
    })
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
  }

  const handleContainerSize = () => {
    if (!innerWidth || innerWidth > 1000) {
      return data?.length >= 3 ? '100%' : `${(+data?.length / 3) * 100}%`
    } else if (innerWidth > 650) {
      return data?.length >= 2 ? '100%' : '50%'
    } else {
      return '100%'
    }
  }

  return (
    <div className="slick-carousel" style={{ width: handleContainerSize() }}>
      {/*hello*/}
      <Slider
        {...settings}
        slidesToShow={
          !innerWidth || innerWidth > 1000
            ? data?.length >= 3
              ? 3
              : data?.length
            : innerWidth > 650
              ? data?.length >= 2
                ? 2
                : data?.length
              : 1
        }
      >
        {Array.isArray(data) &&
          !!data?.length &&
          data.map((d, index) => (

            <SlickCarouselAntiTheftItem handleClickOrder={handleClickOrder} key={d?.id} dataItem={d} />

          ))}
      </Slider>
    </div>
  )
}

export default SlickCarouselAntiTheft
